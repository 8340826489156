import axios from "axios";
import notifiesActions from "./notifies";

//axios.defaults.timeout = 75000;
const fetchReports = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/adminTool/reports");
      dispatch({ type: "REPORTS_FETCH", payload: response.data });
      return true;
    } catch (e) {
      return false;
    }
  };
};

const fetchExcelReports = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/adminTool/reportsSinader");
      dispatch({ type: "REPORTS_FETCH", payload: response.data });
      return true;
    } catch (e) {
      return false;
    }
  };
};

const createReport = (payload) => {
  return async (dispatch) => {
    try {
	  //const response = await axios.post("/adminTool/reports", { ...payload });
	 const response = await axios.post("https://accioncircular.inosoft.cl/adminTool/reports", { ...payload });
      dispatch(notifiesActions.enqueueNotify("REPORT_CREATE_SUCCESS"));
      dispatch(fetchReports());
      return response.data;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("REPORT_CREATE_FAIL"));
      return false;
    }
  };
};

const createExcelReport = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axios.post("/adminTool/excelbydate", { ...payload });
      dispatch(notifiesActions.enqueueNotify("REPORT_CREATE_SUCCESS"));
      dispatch(fetchExcelReports());
      return response;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("REPORT_CREATE_FAIL"));
      return false;
    }
  };
};

const deleteReportExcel = ({ reportID }) => {
  return async (dispatch) => {
    try {
      await axios.delete("/adminTool/reports?reportID=" + reportID);
      dispatch(notifiesActions.enqueueNotify("REPORT_DELETE_SUCCESS"));
      dispatch(fetchExcelReports());
      return true;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("REPORT_DELETE_FAIL"));
      return false;
    }
  };
};
const deleteReport = ({ reportID }) => {
  return async (dispatch) => {
    try {
      await axios.delete("/adminTool/reports?reportID=" + reportID);
      dispatch(notifiesActions.enqueueNotify("REPORT_DELETE_SUCCESS"));
      dispatch(fetchReports());
      return true;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("REPORT_DELETE_FAIL"));
      return false;
    }
  };
};

const loadDataCreateReport = ({ localID, datetimeInit, datetimeFinish }) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        "/adminTool/reports/loadDataCreateReport",
        {
          params: {
            localID,
            datetimeInit,
            datetimeFinish,
          },
        }
      );
      dispatch({ type: "REPORTS_SET_DATA_MODAL", payload: response.data });
      return true;
    } catch (err) {
      return false;
    }
  };
};

const loadDataCreateExcelReport = ({ localID, datetimeInit, datetimeFinish }) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        "/adminTool/reports/loadDataCreateReport",
        {
          params: {
            localID,
            datetimeInit,
            datetimeFinish,
          },
        }
      );
      dispatch({ type: "REPORTS_SET_DATA_MODAL", payload: response.data });
      return true;
    } catch (err) {
      return false;
    }
  };
};

const setCreateModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REPORTS_SET_CREATE_MODAL", payload: value });
};

const setCreateExcelModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REPORTS_SET_CREATE_MODAL", payload: value });
};

const setDeleteModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REPORTS_SET_DELETE_MODAL", payload: value });
};

const setDataModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REPORTS_SET_DATA_MODAL", payload: value });
};

export default {
  fetchReports,
  fetchExcelReports,
  deleteReport,
  createReport,
  createExcelReport,
  loadDataCreateReport,
  loadDataCreateExcelReport,
  setCreateModal,
  setCreateExcelModal,
  setDeleteModal,
  setDataModal,
  deleteReportExcel,
};
